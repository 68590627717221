.modal-edit-wallet {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
}

.modal-edit-wallet > div:nth-child(1) {
    display: flex;
    margin-bottom: 20px;
    justify-content: left;
}

.modal-edit-wallet > div:nth-child(1) > p {
    font-size: 23px;
    font-weight: 500;
    margin-left: 20px;
}

.modal-edit-wallet > div:nth-child(1) > button {
    justify-content: center;
    border-radius: 13px;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    display: flex;
    border: none;
    height: 35px;
    width: 35px;
}

.modal-edit-wallet > div:nth-child(2) {
    width: 100%;
}

.modal-edit-wallet > div:nth-child(2) > input {
    width: 100%;
}

.modal-edit-wallet > .modal-edit-wallet-list {
    display: flex;
    flex-direction: column;
}

.modal-edit-wallet > .modal-edit-wallet-list > .crypto-list {
    display: flex;
    flex-direction: column;
}

.modal-edit-wallet > .modal-edit-wallet-list > .crypto-list > .crypto-item {
    width: 100%;
    display: flex;
    margin: 5px 0;
    flex-direction: row;
}

.modal-edit-wallet > .modal-edit-wallet-list > .crypto-list > .crypto-item:first-child {
    margin-top: 15px;
}

.modal-edit-wallet > .modal-edit-wallet-list > .crypto-list > .crypto-item:last-child {
    margin-bottom: 15px;
}

.modal-edit-wallet > .modal-edit-wallet-list > .crypto-list > .crypto-item > input {
    width: 25px;
    margin-right: 10px;
}

.modal-edit-wallet > .modal-edit-wallet-list > .crypto-list > .crypto-item > div.part1 {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.modal-edit-wallet > .modal-edit-wallet-list > .crypto-list > .crypto-item > div.part1 > img {
    width: 50px;
    height: 50px;
}

.modal-edit-wallet > .modal-edit-wallet-list > .crypto-list > .crypto-item > div.part2 {
    width: 100%;
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    justify-content: center;
}

.modal-edit-wallet > .modal-edit-wallet-list > .crypto-list > .crypto-item > div.part3 {
    display: flex;
    flex-direction: row;
}

.modal-edit-wallet > .modal-edit-wallet-list > .crypto-list > .crypto-item > div.part3 > button {
    border: none;
    margin: 0 5px;
    cursor: pointer;
    background: none;
}