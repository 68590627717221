/* INPUT GLOBAL LINE START */
.input-global {
  border: none;
  font-size: 16px;
  padding: 15px 20px;
  border-radius: 15px;
  color: var(--text-light);
  background-color: var(--background-input-color);
}

.input-global.outlined {
  color: var(--text-light);
  background-color: var(--white-color);
  border: 1.8px solid var(--border-input-color);
}

.input-global.transparent {
  background-color: transparent;
}

.input-global::placeholder {
  color: var(--text-light);
}

.input-global:focus {
  outline: none;
}
/* INPUT GLOBAL LINE END */

/* SELECT GLOBAL LINE START */
.select-wrapper {
  width: 100%;
  cursor: pointer;
}

.select-wrapper .select-global__control {
  width: 100%;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 7px 10px;
  border-radius: 15px;
  color: var(--text-light);
  background-color: var(--background-input-color);
}

.select-wrapper select {
  width: 100%;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 14.2px 15px;
  border-radius: 15px;
  color: var(--text-light);
  background-color: var(--background-input-color);
}

.select-wrapper.outlined .select-global__control,
.select-wrapper.outlined select {
  color: var(--text-light);
  background-color: var(--white-color);
  border: 1.8px solid var(--border-input-color);
}

.select-wrapper.transparent .select-global__control,
.select-wrapper.transparent select {
  background-color: transparent;
}

.select-wrapper .select-global__control::placeholder,
.select-wrapper select::placeholder {
  color: var(--text-light);
}

.select-wrapper .select__control--is-focused:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

.select-wrapper select:focus {
  outline: none;
}

/* SELECT GLOBAL LINE END */

/* BUTTON GLOBAL LINE START */
.button-global {
  width: 100%;
  border: none;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  transition: 0.2s;
  padding: 13px 20px;
  align-items: center;
  border-radius: 15px;
  justify-content: center;
  color: var(--white-color);
  background-color: var(--secondary-color);
}

.button-global.min-button {
  font-size: 15px;
  padding: 7px 10px;
}

.button-global.rounded {
  border-radius: 50px;
}

.button-global.rotate-inf {
  animation: rotate-inf 1.5s linear infinite;
}

.button-global:hover {
  color: var(--white-hover-color);
  background-color: var(--secondary-hover-color);
}
.button-global.no-hover:hover {
  color: var(--white-color);
  background-color: var(--secondary-color);
}


.button-global.outlined {
  color: var(--secondary-color);
  background-color: transparent;
  border: 2px solid var(--secondary-color);
}
.button-global.outlined:hover {
  color: var(--white-color);
  border: 2px solid var(--secondary-color);
  background-color: var(--secondary-color);
}
.button-global.outlined:hover > img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}
.button-global.outlined.no-hover:hover {
  background-color: transparent;
  color: var(--secondary-hover-color);
  border: 2px solid var(--secondary-hover-color);
}
.button-global.outlined.no-hover:hover > img {
  filter: brightness(0) saturate(100%) invert(40%) sepia(92%) saturate(5893%) hue-rotate(193deg) brightness(94%) contrast(105%);
}


.button-global.link {
  border: none;
  font-size: 20px;
  color: var(--secondary-color);
  background-color: transparent;
}
.button-global.link:hover {
  border: none;
  color: var(--secondary-hover-color);
  background-color: transparent;
}
.button-global.link:hover > img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}
.button-global.link.no-hover:hover {
  color: var(--secondary-hover-color);
  background-color: transparent;
}
.button-global.link.no-hover:hover > img {
  filter: brightness(0) saturate(100%) invert(40%) sepia(92%) saturate(5893%) hue-rotate(193deg) brightness(94%) contrast(105%);
}

.button-global > img.icon-left {
  margin-right: 10px;
}

.button-global > img.icon-right {
  margin-left: 10px;
}

@keyframes rotate-inf {
  to { transform: rotate(360deg); }
}
/* BUTTON GLOBAL LINE END */


/* SEPARATED LINE START */
.separated-container {
  width: 90%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.separated-container > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separated-container > div > div:first-child {
  width: 45%;
  height: 2px;
  background-color: var(--fill-color);
}

.separated-container > div > div:nth-child(2) {
  width: 10%;
  text-align: center;
  color: var(--fill-color);
}

.separated-container > div > div:last-child {
  width: 45%;
  height: 2px;
  background-color: var(--fill-color);
}

.separated-container.line > div > div:first-child {
  width: 50%;
  height: 2px;
  background-color: var(--fill-color);
}

.separated-container.line > div > div:last-child {
  width: 50%;
  height: 2px;
  background-color: var(--fill-color);
}
/* SEPARATED LINE END */


/* BUTTON PHONE START */
.container-button-phone {
  position: fixed;
  bottom: 50px;
  right: 50px;
}

.button-phone {
  padding: 15px;
  border: none;
  color: #fff;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  justify-content: center;
  flex-direction: column;
  background-color: var(--primary-color);
  transition: all 0.2s ease;
}

.button-phone:active {
  transform: scale(0.96);
}

.button-phone:before,
.button-phone:after {
  position: absolute;
  content: "";
  width: 150%;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  z-index: -1000;
  background-repeat: no-repeat;
}

.button-phone:hover {
  transform: translateY(-5px);
  animation: all 0.4s ease-in-out;
  box-shadow: 0px 3px 7px 4px var(--shadow-color);
}

.button-phone:hover:before {
  top: -70%;
  background-image: radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, var(--primary-color) 20%, transparent 30%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--primary-color) 15%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
    10% 10%, 18% 18%;
  background-position: 50% 120%;
  animation: greentopBubbles 0.6s ease;
}

@keyframes greentopBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.button-phone:hover::after {
  bottom: -70%;
  background-image: radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--primary-color) 15%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
  background-position: 50% 0%;
  animation: greenbottomBubbles 0.6s ease;
}

@keyframes greenbottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }

  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }

  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
/* BUTTON PHONE END */

/* LOADING START */
.container-loading {
  width: 100%;
  height: 100%;
}

.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%,#0000) top/9px 9px no-repeat,
         conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
  animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
  100% {
     transform: rotate(1turn);
  }
}
/* LOADING END */

/* LOADING BUTTON START */
.loader-button.primary {
  border: 2px solid var(--primary-color);
  border-left-color: transparent;
}

.loader-button.secondary {
  border: 2px solid var(--secondary-color);
  border-left-color: transparent;
}

.loader-button.black {
  border: 2px solid var(--title-black);
  border-left-color: transparent;
}

.loader-button {
  border: 2px solid var(--white-color);
  border-left-color: transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/* LOADING BUTTON END */

@media (max-width: 768px) {
  .container-button-phone {
    bottom: 20px;
    right: 15px;
  }
  .button-phone {
    transition: none;
    transform: none;
    box-shadow: none;
    padding: 10px;
  }
  .button-phone img {
    width: 20px;
    height: 20px;
  }
  .button-phone:active {
    transform: none;
  }
  .button-phone:hover {
    transform: none;
    animation: none;
    box-shadow: none;
  }
  .button-phone:before,
  .button-phone:after {
    animation: none;
    background-image: none;
  }
  .button-phone:hover:before,
  .button-phone:hover:after {
    animation: none;
  }
}