/* IMPORTING FONTS */

/* FONT INTER START */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* FONT INTER END */

/* FONT DAYS ONE START */
@import url('https://fonts.googleapis.com/css2?family=Days+One&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* FONT DAYS ONE END */

*, ::after, ::before {
    box-sizing: border-box;
    font-family: 'Inter';
    padding: 0;
    margin: 0;
}

:root {
    /* Chart colors */
    --primary-color: #2044E0;
    --secondary-color: #0087FD;
    --third-color: #F7F8F9;
    --white-color: #FFF;

    /* Text colors */
    --title-black: #151628;
    --text-black: #4a5762;
    --text-light: #5a667c;
    --text-green: #439A8C;
    --text-red: #D45550;

    /* Input colors */
    --background-input-color: #f4f8fe;
    --background-button-color: #E3E8F2;
    --border-input-color: #a9a9a9;

    /* Box-Shadow colors */
    --shadow-color: rgba(0,0,0,0.1);
    --shadow-light-color: rgba(139, 139, 139, 0.1);

    /* Fill colors */
    --fill-color: #e0e1e3;
    --box-form-color: #FEFEFE;
    --border-form-color: #E1E3E8;

    /* Overlay colors */
    --overlay-color: rgba(0, 0, 0, 0.5);
    --overlay-primary-color: rgba(0, 135, 253, 0.20);

    /* Hover colors */
    --secondary-hover-color: #0072d5;
    --white-hover-color: #ededed;
    --table-hover-color: #E8EBF5;
}

body {
    background-color: var(--third-color);
    font-family: Roboto, sans-serif;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    overflow-x: hidden;
}

a {
    transition: 0.4s;
    color: var(--secondary-color);
    text-decoration: none;
}

a:hover {
    color: var(--secondary-hover-color);
}

p {
    color: var(--text-black);
}

::-webkit-scrollbar {
    width: 7px;
    height: 9px;
}
::-webkit-scrollbar-button {
    width: 80px;
    height: 0;
}
::-webkit-scrollbar-thumb {
    background: var(--border-input-color);
    border: 0px none var(--white-color);
    border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
    background: var(--border-input-color);
}
::-webkit-scrollbar-thumb:active {
    background: var(--border-input-color);
}
::-webkit-scrollbar-track {
    background: var(--white-color);
    border: 0px none var(--white-color);
    border-radius: 100px;
}
::-webkit-scrollbar-track:hover {
    background: var(--white-color);
}
::-webkit-scrollbar-track:active {
    background: var(--white-color);
}
::-webkit-scrollbar-corner {
    background: transparent;
}