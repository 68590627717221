.wrapper-modal {
    width: 365px;
    height: 100%;
    border-radius: 15px;
    padding-bottom: 15px;
    background-color: var(--white-color);
    box-shadow: 0px 0px 10px 3px var(--shadow-light-color);
}

.head-modal {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.head-modal > p {
    color: var(--text-black);
    margin-bottom: 20px;
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    font-size: 25px;
}

.head-modal > div {
    width: 90%;
    position: relative;
}

.head-modal > div > button {
    background-color: var(--secondary-color);
    transform: translateY(-50%);
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    position: absolute;
    transition: 0.4s;
    cursor: pointer;
    display: flex;
    border: none;
    height: 40px;
    width: 40px;
    right: 5px;
    top: 50%;
}

.head-modal > div > button:hover {
    background-color: var(--secondary-hover-color);
}

.head-modal > div > button > img {
    transition: 0.4s;
}

.head-modal > div > button:hover > img {
    transform: rotate(180deg);
}

.middle-modal {
    width: 100%;
}

.footer-modal {
    display: flex;
    justify-content: center;
}

.footer-modal > div {
    width: 90%;
}

.dropdown-overlay,
.modal-bottom-menu {
    display: none;
}

@media screen AND (max-width: 768px) {
    .wrapper-modal {
        width: 100%;
        box-shadow: none;
        height: max-content;
        border-radius: 15px;
        padding-bottom: 15px;
        background-color: var(--white-color);
    }

    /* État initial de l'overlay (masqué) */
    .modal-bottom-overlay {
        left: 0;
        opacity: 1;
        width: 100%;
        height: 100%;
        bottom: -100%;
        z-index: 9999;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        transition: opacity 0.3s ease-in-out;
        background-color: var(--overlay-color);
    }

    .modal-bottom-overlay.show {
        bottom: 0;
        opacity: 1;
    }

    .modal-bottom-overlay.close {
        opacity: 0;
        pointer-events: none;
    }

    /* État initial du menu déroulant (masqué) */
    .modal-bottom-menu {
        opacity: 0;
        width: 100%;
        bottom: -100%;
        display: block;
        padding: 20px 0;
        position: absolute;
        pointer-events: none;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        background-color: var(--white-color);
        transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Transition pour animation d'entrée/sortie */
    }

    /* État visible du menu déroulant */
    .modal-bottom-menu.show {
        bottom: 0;
        opacity: 1;
        pointer-events: auto;
    }

    /* Ajout de la transition de fermeture */
    .modal-bottom-menu.close {
        bottom: -100%;
        opacity: 0;
        pointer-events: none;
    }

    .modal-bottom-menu a,
    .modal-bottom-menu button {
        padding: 0;
        width: 100%;
        border: none;
        height: 44px;
        display: block;
        cursor: pointer;
        font-size: 16px;
        background: none;
        transition: 0.4s;
        text-decoration: none;
        color: var(--text-black);
        font-family: Roboto, sans-serif;
    }

    .modal-bottom-menu .modal-bottom-link {
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .modal-bottom-menu .modal-bottom-item {
        display: flex;
        margin-top: 10px;
        margin-left: 20px;
        padding: 10px 15px;
        margin-bottom: 10px;
        align-items: center;
    }

    .modal-bottom-menu .modal-bottom-item:first-child {
        border-top: none;
    }

    .modal-bottom-menu .icon-container {
        margin-right: 20px;
        width: 24px;
        height: 24px;
    }
}