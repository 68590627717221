.container-board {
    display: flex;
    flex-direction: row;
}

.container-board-children {
    width: 100%;
    height: max-content;
    padding: 20px;
    padding-left: 40px;
}

@media screen AND (max-width: 768px) {
    .container-board-children {
        padding: 20px;
        padding-left: 20px;
    }
}