.container-spinner {
    top: 50%;
    left: 50%;
    width: 100%;
    z-index: 9999;
    height: 100vh;
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transform: translate(-50%, -50%);
	background-color: var(--white-color);
}