.wrapper-upgrade {
    width: 100%;
    height: 100vh;
    position: absolute;
    padding-top: 100px;
}

.wrapper-upgrade > .modal-out {
    top: 50px;
    left: 50px;
    position: absolute;
}

.wrapper-upgrade > .modal-out > button {
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.wrapper-upgrade > .modal-title {
    width: 100%;
    text-align: center;
}

.wrapper-upgrade > .modal-title > span {
    color: var(--title-black);
    font-weight: bold;
    font-size: 35px;
}

.wrapper-upgrade > .modal-title > span > span {
    background: -webkit-linear-gradient(#0867C5, #0087FD, #1EB8FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wrapper-upgrade > .modal-title > p {
    color: var(--text-light);
    margin-top: 20px;
    font-size: 20px;
}

.wrapper-upgrade > .container-modal-upgrade {
    width: 100%;
    display: flex;
    margin-top: 40px;
    flex-direction: row;
    justify-content: center;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card {
    width: 400px;
    display: flex;
    padding: 20px;
    margin: 0 20px;
    position: relative;
    border-radius: 20px;
    flex-direction: column;
    border: 1px solid var(--border-form-color);
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card:last-child {
    border: none;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card:last-child::before {
    content: "";
    position: absolute;
    pointer-events: none;
    inset: 0;
    padding: 2px;
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(8,103,197,1) 0%, rgba(0,135,253,1) 39%, rgba(30,184,255,1) 100%); 
    mask:
      linear-gradient(#000 0 0) content-box, 
      linear-gradient(#000 0 0);
    mask-composite: exclude; 
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child {
    display: flex;
    flex-direction: column;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(1) {
    text-align: center;
    margin-bottom: 20px;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(1) > span {
    color: var(--title-black);
    font-size: 30px;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(1) > p {
    color: var(--text-black);
    padding: 0 10px;
    font-size: 16px;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(2) {
    display: flex;
    margin-bottom: 15px;
    align-items: flex-end;
    justify-content: center;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(2) > div:first-child {
    position: relative;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(2) > div:first-child > span:first-child {
    color: var(--title-black);
    font-size: 42px;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(2) > div:first-child > span:last-child {
    color: var(--text-light);
    position: absolute;
    top: 0;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(2) > div:last-child > span {
    color: var(--text-light);
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(3) > button {
    z-index: 99;
    font-size: 20px;
    color: var(--white-color);
    background: rgb(8,103,197);
    background: linear-gradient(130deg, rgba(8,103,197,1) 0%, rgba(0,135,253,1) 39%, rgba(30,184,255,1) 100%);
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(4) {
    position: absolute;
    top: -17px;
    left: 50%;
    padding: 2px;
    border-radius: 10px;
    transform: translateX(-50%);
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(4)::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(8,103,197,1) 0%, rgba(0,135,253,1) 39%, rgba(30,184,255,1) 100%); 
    mask:
      linear-gradient(#000 0 0) content-box, 
      linear-gradient(#000 0 0);
    mask-composite: exclude; 
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(4) > div {
    padding: 5px 7px;
    background-color: var(--white-color);
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:first-child > div:nth-child(4) > div > span {
    background: -webkit-linear-gradient(#0867C5, #0087FD, #1EB8FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:last-child > ul {
    margin-top: 10px;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:last-child > ul > li {
    display: flex;
    margin: 25px 0;
    list-style: none;
    align-items: flex-start;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:last-child > ul > li > img {
    margin-right: 10px;
}

.wrapper-upgrade > .container-modal-upgrade > .modal-card > div:last-child > ul > li > span {
    color: var(--text-light);
}