.container-modal.setting {
    right: 90px!important;
}

.wrapper-modal.setting {
    width: 235px;
    height: 100%;
    padding-top: 15px;
    border-radius: 15px;
    padding-bottom: 15px;
    background-color: var(--white-color);
}

.footer-modal.setting {
    display: flex;
    justify-content: center;
}

.footer-modal.setting > div {
    width: 90%;
}

.wrapper-modal.setting .middle-modal a,
.wrapper-modal.setting .middle-modal button {
    padding: 0;
    width: 100%;
    border: none;
    height: 44px;
    display: block;
    cursor: pointer;
    font-size: 16px;
    background: none;
    transition: 0.4s;
    text-decoration: none;
    color: var(--text-black);
    font-family: Roboto, sans-serif;
}

.wrapper-modal.setting .middle-modal .modal-bottom-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
}

.wrapper-modal.setting .middle-modal .modal-bottom-item {
    width: 100%;
    display: flex;
    margin-top: 10px;
    padding: 10px 25px;
    margin-bottom: 10px;
    align-items: center;
    transition: background-color 0.2s ease;
}

.wrapper-modal.setting .middle-modal .modal-bottom-item:hover {
    background-color: var(--fill-color);
}


.wrapper-modal.setting .middle-modal .modal-bottom-item:first-child {
    border-top: none;
}

.wrapper-modal.setting .middle-modal .icon-container {
    margin-right: 20px;
    width: 24px;
    height: 24px;
}