.middle-modal.add-crypto {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.middle-modal.add-crypto > div {
    width: 90%;
}

.footer-modal.add-crypto {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-modal.add-crypto button {
    width: 90%;
}

.add-crypto-input {
    width: 100%;
    margin-bottom: 15px;
}

.add-crypto-container-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.add-crypto-container-input > .add-crypto-input {
    width: 47%;
    position: relative;
}