/* Styles généraux pour la version desktop (PC) */
.container-sidebar {
    width: 85px; /* Largeur en mode non actif */
    min-height: 100vh;
    max-height: 100%;
    background-color: var(--white-color);
    z-index: 9999;
    transition: width 0.4s ease-in-out;
    position: relative;
}

.container-sidebar.active {
    width: 250px; /* Largeur en mode actif */
}

/* Toggle Sidebar (flèche) */
.toggle-sidebar {
    opacity: 0;
    position: absolute;
    top: 450px;
    right: -15px;
    width: 35px;
    height: 35px;
    background-color: var(--white-color);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 5px var(--shadow-color);
    transition: all 0.4s ease;
}

.toggle-sidebar.opened {
    opacity: 1;
}

.toggle-sidebar img {
    width: 80%;
    height: 80%;
    position: relative;
    transition: transform 0.4s ease;
}

.toggle-sidebar img.rotate {
    transform: rotate(180deg);
}

.container-sidebar:hover .toggle-sidebar {
    opacity: 1;
}

/* Wrapper Sidebar */
.wrapper-sidebar {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    z-index: 999;
    align-items: center;
}

/* Ajuster le padding à gauche lorsque la sidebar est active */
.container-sidebar.active .wrapper-sidebar {
    padding-left: 25px;
    align-items: flex-start;
}

/* Logo Sidebar Minimisé */
.logo-sidebar-min {
    width: 35px;
    height: 40px;
    display: flex;
    cursor: pointer;
    max-height: 200px;
    margin-bottom: 30px;
    justify-content: center;
    transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

/* Logo Sidebar Full (par défaut caché) */
.logo-sidebar-full {
    width: 0;
    height: 0;
    opacity: 0;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    justify-content: center;
    transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.container-sidebar.active .logo-sidebar-min {
    width: 0;
    height: 0;
    opacity: 0;
    margin-bottom: 0;
}

.container-sidebar.active .logo-sidebar-full {
    width: 90%;
    opacity: 1;
    height: 40px;
    max-height: 200px;
    overflow: visible;
    margin-bottom: 30px;
    justify-content: start;
}

/* List Sidebar */
.list-sidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0; /* Retirer le padding pour aligner les éléments */
}

/* Style général des liens */
.list-sidebar > a {
    width: 100%;
    display: flex;
    margin-bottom: 25px;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

/* Icônes et texte alignés */
.sidebar-item {
    width: 85px;
    height: 40px;
    display: flex;
    padding-left: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: width 0.4s ease-in-out, padding-left 0.4s ease-in-out;
}

/* En mode actif, on étend la largeur */
.container-sidebar.active .sidebar-item {
    width: 100%;
    justify-content: start;
    transition: transform .1s ease-in-out, background-color .2s;
}

.container-sidebar.active .sidebar-item:active {
    transform: scale(90%);
}

/* Conteneur d'icônes */
.icon-container {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Texte associé à chaque icône */
.list-sidebar > a > div > p {
    opacity: 0;
    left: 90px;
    font-weight: 500;
    transition: none;
    position: absolute;
    padding: 10px 15px;
    border-radius: 7px;
    white-space: nowrap;
    pointer-events: none;
    color: var(--text-black);
    transform: translateX(-10px);
    background-color: var(--white-color);
}

/* Texte visible lorsque la barre latérale est active */
.container-sidebar.active .list-sidebar > a > div > p {
    left: 35px;
    opacity: 1;
    padding: 0;
    border-radius: 0;
    position: relative;
    pointer-events: auto;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.container-sidebar.active .list-sidebar > a > div:hover > p {
    box-shadow: none;
    color: var(--secondary-color);
    background-color: transparent;
}

/* Texte visible lors du survol si la barre est inactive */
.list-sidebar > a > div:hover > p {
    opacity: 1;
    left: 100px;
    padding: 10px 20px;
    background-color: var(--white-color);
    box-shadow: -3px 4px 4px 0px var(--shadow-color);
    transition: opacity 0.3s ease, left 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.list-sidebar > a > div:hover > div {
    filter: brightness(0) saturate(100%) invert(37%) sepia(27%) saturate(6068%) hue-rotate(192deg) brightness(101%) contrast(106%);
}

/* Styles spécifiques pour la version mobile (footer bar) */
@media screen and (max-width: 768px) {
    .container-sidebar {
        position: fixed;
        top: 0;
        left: -100%; /* Commence hors de l'écran */
        width: 250px;
        height: 100vh;
        transition: left 0.4s ease-in-out;
    }

    .container-sidebar.active {
        left: 0; /* Déplace la sidebar dans l'écran */
    }

    .container-sidebar.active .logo-sidebar-full {
        width: 70%;
        opacity: 1;
        height: 40px;
        max-height: 200px;
        overflow: visible;
        margin-bottom: 30px;
        justify-content: start;
    }
}