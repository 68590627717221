.modal-my-wallet {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
}

.modal-my-wallet > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
}

.modal-my-wallet > div:nth-child(1) > p {
    font-size: 23px;
    font-weight: 500;
}

.modal-my-wallet > div:nth-child(1) > button {
    background-color: var(--secondary-color);
    justify-content: center;
    border-radius: 13px;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    display: flex;
    border: none;
    height: 40px;
    width: 40px;
}

.modal-my-wallet > div:nth-child(1) > button:hover {
    background-color: var(--secondary-hover-color);
}

.modal-my-wallet > div:nth-child(1) > button > img {
    transition: 0.4s;
}

.modal-my-wallet > div:nth-child(1) > button:hover > img {
    transform: rotate(-180deg);
}

.modal-my-wallet > .modal-my-wallet-gest {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-my-wallet > .modal-my-wallet-gest > span,
.modal-my-wallet > .modal-my-wallet-gest > button {
    border: none;
    cursor: pointer;
    font-size: 17px;
    background-color: transparent;
    color: var(--secondary-color);
}

.modal-my-wallet > .modal-my-wallet-list {
    display: flex;
    flex-direction: column;
}

.modal-my-wallet > .modal-my-wallet-list > .wallet-list {
    display: flex;
    flex-direction: column;
}

.modal-my-wallet > .modal-my-wallet-list > .wallet-list > .wallet-item {
    width: 100%;
    display: flex;
    margin: 5px 0;
    flex-direction: row;
}

.modal-my-wallet > .modal-my-wallet-list > .wallet-list > .empty-wallet-list {
    width: 100%;
    display: flex;
    margin: 5px 0;
    margin-top: 15px;
    margin-bottom: 15px;
    flex-direction: row;
}

.modal-my-wallet > .modal-my-wallet-list > .wallet-list > .wallet-item:first-child {
    margin-top: 15px;
}

.modal-my-wallet > .modal-my-wallet-list > .wallet-list > .wallet-item:last-child {
    margin-bottom: 15px;
}

.modal-my-wallet > .modal-my-wallet-list > .wallet-list > .wallet-item > input {
    width: 25px;
    margin-right: 10px;
}

.modal-my-wallet > .modal-my-wallet-list > .wallet-list > .wallet-item > div.part1 {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.modal-my-wallet > .modal-my-wallet-list > .wallet-list > .wallet-item > div.part1 > img {
    width: 50px;
    height: 50px;
}

.modal-my-wallet > .modal-my-wallet-list > .wallet-list > .wallet-item > div.part2 {
    width: 100%;
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    justify-content: center;
}

.modal-my-wallet > .modal-my-wallet-list > .wallet-list > .wallet-item > div.part3 {
    display: flex;
    flex-direction: row;
}

.modal-my-wallet > .modal-my-wallet-list > .wallet-list > .wallet-item > div.part3 > button {
    border: none;
    margin: 0 5px;
    cursor: pointer;
    background: none;
}