.container-modal.notification {
    right: 180px!important;
}

.head-modal.notification p {
    width: 100%;
    margin-bottom: 0;
    margin-left: 50px;
    text-align: start;
}

.middle-modal.notification {
    width: 99%;
    height: 250px;
    overflow-y: scroll;
}

.container-modal.notification .notification-item {
    width: auto;
    display: flex;
    margin: 0 10px;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    border-radius: 7px;
    padding-right: 35px;
    margin-bottom: 15px;
    flex-direction: row;
    justify-content: space-between;
    transition: background-color 0.2s ease;
}

.container-modal.notification .notification-item:hover {
    background-color: var(--fill-color);
}

.container-modal.notification .notification-item .notification-part-one {
    width: 200px;
    display: flex;
    padding-right: 15px;
    flex-direction: column;
    justify-content: center;
}

.container-modal.notification .notification-item .notification-part-one p {
    font-size: 17px;
    color: var(--text-black);
}

.container-modal.notification .notification-item .notification-part-one span {
    font-size: 12px;
    color: var(--text-light);
}

.container-modal.notification .notification-item .notification-part-two {
    width: 130px;
}

.container-modal.notification .notification-item .notification-part-two img {
    width: 100%;
    height: auto;
    margin: 10px 0;
    border-radius: 10px;
}

.container-modal.notification .notification-item .notification-close {
    top: 5px;
    right: 5.5px;
    cursor: pointer;
    position: absolute;
}

@media screen AND (max-width: 768px) {
    .head-modal.notification p {
        width: 100%;
        margin-bottom: 0;
        margin-left: 30px;
        text-align: start;
    }
    
    .middle-modal.notification {
        height: 250px;
        overflow-y: scroll;
    }
    
    .middle-modal.notification .notification-item {
        width: 100%;
        display: flex;
        position: relative;
        padding-left: 20px;
        padding-right: 50px;
        margin-bottom: 15px;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .middle-modal.notification .notification-item .notification-part-one {
        width: 150px;
        display: flex;
        padding-right: 15px;
        flex-direction: column;
        justify-content: center;
    }

    .middle-modal.notification .notification-item .notification-part-two {
        width: 130px;
    }
    
    .middle-modal.notification .notification-item .notification-part-two img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    
    .middle-modal.notification .notification-item .notification-close {
        top: 50%;
        right: 12px;
        cursor: pointer;
        position: absolute;
        transform: translateY(-50%);
    }
}