.container-navbar {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: end;
}

.container-navbar > .wrapper-navbar {
    width: 100%;
    display: flex;
    margin-right: 60px;
    align-items: center;
    justify-content: end;
}

.container-navbar > .wrapper-navbar > .interaction-navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-navbar > .wrapper-navbar > .logo-navbar-min {
    display: none;
}

.container-navbar > .wrapper-navbar > .interaction-navbar > div {
    display: flex;
    align-items: center;
}

.container-navbar > .wrapper-navbar > .interaction-navbar > div > div.button-navbar-outlined:first-child {
    cursor: pointer;
    transition: 0.4s;
    margin-left: 30px;
    margin-right: 30px;
    padding: 15px 15px;
    border-radius: 12px;
    letter-spacing: 1px;
    color: var(--white-color);
    background-color: var(--secondary-color);
}

.container-navbar > .wrapper-navbar > .interaction-navbar > div > div.button-navbar-outlined:first-child:hover {
    color: var(--white-hover-color);
    background-color: var(--secondary-hover-color);
}

.container-navbar > .wrapper-navbar > .interaction-navbar > .icon-navbar {
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
    align-items: center;
    justify-content: center;
}

.container-navbar > .wrapper-navbar > .interaction-navbar > .icon-navbar > button,
.container-navbar > .wrapper-navbar > .interaction-navbar > .icon-navbar > a {
    border: none;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: transform .1s ease-in-out, background-color .2s;
}

.container-navbar > .wrapper-navbar > .interaction-navbar > .icon-navbar > button:active,
.container-navbar > .wrapper-navbar > .interaction-navbar > .icon-navbar > a:active {
    transform: scale(90%);
}

.container-navbar > .wrapper-navbar > .interaction-navbar > .icon-navbar > button img,
.container-navbar > .wrapper-navbar > .interaction-navbar > .icon-navbar > a img {
    transition: transform 0.2s ease-in-out;
}

.container-navbar > .wrapper-navbar > .interaction-navbar > .icon-navbar > button img:hover,
.container-navbar > .wrapper-navbar > .interaction-navbar > .icon-navbar > a img:hover {
    filter: brightness(0) saturate(100%) invert(37%) sepia(27%) saturate(6068%) hue-rotate(192deg) brightness(101%) contrast(106%);
}

/* .container-navbar > .wrapper-navbar > .interaction-navbar > .icon-navbar > button:last-child {
    margin-right: 0;
    margin-left: 30px;
} */

.container-navbar > .wrapper-navbar > .interaction-navbar div.my-wallet > img {
    width: 25px;
    margin-left: 5px;
}

.container-navbar > .wrapper-navbar > .interaction-navbar div.my-wallet.outlined {
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 15px;
    color: var(--secondary-color);
    background-color: transparent;
    border: 2px solid var(--secondary-color);
}

.container-navbar > .wrapper-navbar > .interaction-navbar div.my-wallet.outlined > div {
    margin: 0;
    padding: 10px;
    margin-left: 10px;
    color: var(--secondary-color);
    background-color: transparent;
}

.container-navbar > .wrapper-navbar > .interaction-navbar div.my-wallet.outlined > img {
    margin-right: 10px;
}

.container-navbar > .wrapper-navbar > .interaction-navbar > div.hidden {
    display: none;
}

.container-navbar > .wrapper-navbar > .interaction-navbar .container-modal {
    opacity: 0;
    top: -600px;
    right: 275px;
    z-index: 99999;
    position: absolute;
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.container-navbar > .wrapper-navbar > .interaction-navbar .container-modal.show {
    top: 100px;
    opacity: 1;
}

.container-modal > .wrapper-navbar > .interaction-navbar .modal-my-wallet {
    background-color: var(--white-color);
}

.container-navbar > .wrapper-navbar > .interaction-navbar > a > img {
    width: 25px;
}

@media screen and (max-width: 768px) {
    /* Styles pour les liens dans la navbar */
    .container-navbar > .wrapper-navbar > .interaction-navbar > a {
        display: none;
    }

    /* Style général pour le wrapper de la navbar */
    .container-navbar > .wrapper-navbar {
        width: 100%;
        display: flex;
        margin-left: 30px;
        margin-right: 30px;
        align-items: center;
        justify-content: space-between;
    }

    .container-navbar > .wrapper-navbar > .logo-navbar-min {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .container-navbar > .wrapper-navbar > .logo-navbar-min > img {
        width: 40px;
    }

    .container-navbar > .wrapper-navbar > .interaction-navbar > .icon-navbar.refresh {
        display: none;
    }

    /* Styles pour le premier enfant dans div */
    .container-navbar > .wrapper-navbar > .interaction-navbar > div > div:first-child {
        cursor: pointer;
        margin-left: 0;
        margin-right: 0;
        font-size: 12px;
        transition: 0.4s;
        padding: 10px 15px;
    }

    /* Styles pour la modal sur mobile */
    .container-navbar > .wrapper-navbar > .interaction-navbar > div > .container-modal {
        opacity: 0;
        top: -600px;
        width: 100%;
        height: 100vh;
        display: flex;
        z-index: 99999;
        position: fixed;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
        justify-content: center;
        background-color: var(--overlay-color);
        transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
    }
    
    /* Styles lorsque la modal est visible */
    .container-navbar > .wrapper-navbar > .interaction-navbar > div > .container-modal.show,
    .container-modal.show {
        top: 50%;
        right: 0;
        left: 50%;
        opacity: 1;
        padding: 20px;
        z-index: 10000;
        border-radius: 10px;
        transform: translate(-50%, -50%);
    }

    /* Styles pour l'overlay sur mobile */
    .overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: var(--overlay-color);
    }

    /* Styles spécifiques pour la modal mobile */
    .mobile-modal {
        width: 100%;
        height: 100%;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* Styles pour le menu hamburger */
    .toggle-menu {
        width: 35px;
        height: 35px;
        z-index: 999;
        display: flex;
        cursor: pointer;
        margin-left: 20px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
    }
    .toggle-menu img {
        width: 70%;
        height: 70%;
    }
}